import Surrounding1 from "./surounding (1).webp";
import Surrounding2 from "./surounding (2).webp";
import Surrounding3 from "./surounding (3).webp";
import Surrounding4 from "./surounding (4).webp";
import Surrounding5 from "./surounding (5).webp";

const surroundingimages = [
  Surrounding1,
  Surrounding2,
  Surrounding3,
  Surrounding4,
  Surrounding5,
];

export default surroundingimages;
