import React from "react";
import "./footer.scss";
import logo from "../../asset/The-Newton-2-Logo.webp";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img
            className="gambar-logo"
            src={logo}
            alt="logo-citraland-surabaya"
          />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h2>Marketing Gallery</h2>
            <h1>The Newton 2 - Ciputra</h1>
            <p>
              Jl. Karet Sawah No.219, RT.4/RW.4, Karet Semanggi, Kecamatan
              Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta
              12930
            </p>
          </div>
          <div className="contact">Contact us : +6285281249046 (Yudha)</div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">THE NEWTON 2 - CIPUTRA</div>
    </div>
  );
};

export default footer;
