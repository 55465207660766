import React from "react";
import "./about.scss";
import gedung from "../../asset/gedung.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const about = () => {
  const waabout = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285281249046&text=Halo+Yudha%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(about)%20http://thenewton2-ciputra.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="about" className="about">
      <div className="container-about">
        <div className="heading-about">
          <div className="judul-about">
            Living Large at <b>JAKARTA</b>
          </div>
          <div className="desk-about">
            Apartemen Newton 2 di Ciputra World 2, Jakarta, menawarkan fasilitas
            yang lengkap seperti kolam renang, toko, taman bermain, gym, dan
            fitur bangunan hijau untuk mendukung hidup berkelanjutan. Kompleks
            apartemen ini terdiri dari 624 unit, termasuk studio, satu kamar,
            dan dua kamar, yang dirancang untuk memenuhi kebutuhan modern para
            profesional muda. Lokasinya strategis, dekat dengan jalur MRT, LRT,
            Transjakarta, dan berbagai fasilitas lain seperti pusat
            perbelanjaan, kantor diplomatik, bank, rumah sakit, dan universitas.
          </div>
          <div className="button">
            <button className="whatsapp" onClick={waabout}>
              <FontAwesomeIcon icon={faWhatsapp} />
              &nbsp; Whatsapp
            </button>
          </div>
        </div>
        <div className="image">
          {" "}
          <img src={gedung} alt="newton2-gedung" />
        </div>
      </div>
    </div>
  );
};

export default about;
