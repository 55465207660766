import React from "react";
import "./lokasi.scss";
import gambarmobile from "./hero-mobilec.webp";
import gambarlokasi from "./lokasi.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const lokasi = () => {
  const walokasi = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285281249046&text=Halo+Yudha%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Lokasi)%20http://thenewton2-ciputra.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div id="lokasi" className="lokasi">
      <div className="container-lokasi" id="lokasi">
        <div className="headerlokasi">
          <div className="judul-lokasi">
            LUXURY AT GLANCE, TIMELESSLY ELEGANT
          </div>
          <div className="image-lokasi">
            <picture className="gambar">
              <source
                media="(max-width: 768px)"
                srcSet={gambarmobile}
                width="320"
              />
              <source
                media="(min-width: 768px)"
                srcSet={gambarlokasi}
                width="600"
              />
              <img className="gambar" src={gambarlokasi} alt="lokasi" />
            </picture>
            <button className="button" onClick={walokasi}>
              <FontAwesomeIcon icon={faWhatsapp} />
              &nbsp; Whatsapp
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default lokasi;
