import React from "react";
import "./home.scss";
import Hero from "../../section/hero/hero";
import About from "../../section/about/about";
import Fasilitas from "../../section/fasilitas/fasilitas";
import Produk from "../../section/produk/produk";
import Promo from "../../section/promo/promo";
import Lokasi from "../../section/lokasi/lokasi";
import Footer from "../../section/footer/footer";
import ScrollToHashElement from "./ScrollToHashElement.js";

const home = () => {
  return (
    <div className="home">
      <ScrollToHashElement />
      <Hero />
      <About />
      <Promo />
      <Fasilitas />
      <Produk />
      <Lokasi />
      <Footer />
    </div>
  );
};

export default home;
