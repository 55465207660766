import React from "react";
import "./promo.scss";
import Promoimage from "../../asset/hardsel.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Promo = () => {
  const wapromo = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285281249046&text=Halo+Yudha%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Promo)%20http://thenewton2-ciputra.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="container-promo1">
      <div id="promo" className=" container-listpromo">
        <div className="containercontent">
          <div id="promo" className="judul-promo">
            PROMO THE NEWTON 2
          </div>
          <div className="pointpenawaran">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;Tanpa DP</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;Full Furnished</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;Free IPL 2 Tahun</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;Cicilan Start 6 Juta/Bulan</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;Swimming Pool</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;Private Gym</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;Jogging Track</span>
            </div>
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <div className="button-wa-promo">
            <button onClick={wapromo} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Get More Promo
            </button>
          </div>
        </div>
        <div className="containergambar">
          <img className="penawarangambar" src={Promoimage} alt="hardsell" />
        </div>
      </div>
    </div>
  );
};

export default Promo;
