import React from "react";
import "./hero.scss";
import logo from "../../asset/logo-white.webp";
import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

const hero = () => {
  return (
    <div className="hero">
      <div className="container-hero">
        <div className="imagehero">
          <div className="gambarhero">
            <div className="navbar">
              <div className="logonavbar">
                <img className="gambar" src={logo} alt="" />
              </div>
              <div className="buttonwhatsapp">
                <HashLink smooth to="#about">
                  <button className="getstart">
                    Get Started&nbsp;&nbsp;
                    <FontAwesomeIcon icon={faArrowDown} />
                  </button>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default hero;
