import React from "react";
import "./produk.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import apartimages from "../../asset/produk/apartment/apart";
import studioimages from "../../asset/produk/studio/studio";
import br2images from "../../asset/produk/2br/br2";
import br1images from "../../asset/produk/1br/br1";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faBed,
  faLocationDot,
  faMaximize,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const produk = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for your mobile size
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile screens
        },
      },
    ],
  };
  const settings1 = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for your mobile size
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile screens
        },
      },
    ],
  };
  const courtyardwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285281249046&text=Halo+Yudha%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(studio)%20http://thenewton2-ciputra.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const verdantwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285281249046&text=Halo+Yudha%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(1+bedroom)%20http://thenewton2-ciputra.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const theterracewa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285281249046&text=Halo+Yudha%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(2+bedroom)%20http://thenewton2-ciputra.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="produk" className="produk">
      <div className="container-produk">
        <div className="header-produk">
          <div className="judul-produk">
            <b>THE NEWTON 2</b> <br />
            AT CIPUTRA WORLD JAKARTA
          </div>
          <div className="carousel-produk">
            <Slider {...settings1}>
              {apartimages.map((image, index) => (
                <img
                  className="kartugambar2"
                  key={index}
                  src={image}
                  alt={`Courtyard ${index + 1}`}
                />
              ))}
            </Slider>
          </div>
        </div>
        <div className="produk1">
          <div className="kartu">
            <Slider {...settings}>
              {studioimages.map((image, index) => (
                <img
                  className="kartugambar"
                  key={index}
                  src={image}
                  alt={`Courtyard ${index + 1}`}
                />
              ))}
            </Slider>
            <div className="container-title-card">
              <div className="titlecard">
                <div className="judul-rumah1">Studio</div>
                <div className="sub-judul-rumah">
                  <FontAwesomeIcon color="red" icon={faLocationDot} /> Kuningan,
                  Jakarta
                </div>
                <div className="cicilan">
                  <div className="start">Start From</div>
                  <div className="det">
                    <div className="angka">6</div>
                    <div className="ket">Juta/Bulan</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gridspek">
              <FontAwesomeIcon icon={faMaximize} />
              <span> : 24sqm</span>
              <FontAwesomeIcon icon={faBed} />
              <span>: 1</span>
              <FontAwesomeIcon icon={faShower} />
              <span>: 1</span>
            </div>
            <div className="containerwhatsapp">
              <button onClick={courtyardwa} className="whatsapp">
                <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
              </button>
            </div>
          </div>
          <div className="kartu">
            <Slider {...settings}>
              {br1images.map((image, index) => (
                <img
                  className="kartugambar"
                  key={index}
                  src={image}
                  alt={`Courtyard ${index + 1}`}
                />
              ))}
            </Slider>
            <div className="container-title-card">
              <div className="titlecard">
                <div className="judul-rumah1">1 Bedroom</div>
                <div className="sub-judul-rumah">
                  <FontAwesomeIcon color="red" icon={faLocationDot} /> Kuningan,
                  Jakarta
                </div>
                <div className="cicilan">
                  <div className="start">Start From</div>
                  <div className="det">
                    <div className="angka">11</div>
                    <div className="ket">Juta/Bulan</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gridspek">
              <FontAwesomeIcon icon={faMaximize} />
              <span> : 40sqm</span>
              <FontAwesomeIcon icon={faBed} />
              <span>: 1</span>
              <FontAwesomeIcon icon={faShower} />
              <span>: 1</span>
            </div>
            <div className="containerwhatsapp">
              <button onClick={verdantwa} className="whatsapp">
                <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
              </button>
            </div>
          </div>
          <div className="kartu">
            <Slider {...settings}>
              {br2images.map((image, index) => (
                <img
                  className="kartugambar"
                  key={index}
                  src={image}
                  alt={`Courtyard ${index + 1}`}
                />
              ))}
            </Slider>
            <div className="container-title-card">
              <div className="titlecard">
                <div className="judul-rumah1">2 Bedroom</div>
                <div className="sub-judul-rumah">
                  <FontAwesomeIcon color="red" icon={faLocationDot} />
                  Kuningan, Jakarta
                </div>
                <div className="cicilan">
                  <div className="start">Start From</div>
                  <div className="det">
                    <div className="angka">16</div>
                    <div className="ket">Juta/Bulan</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gridspek">
              <FontAwesomeIcon icon={faMaximize} />
              <span> : 60sqm</span>
              <FontAwesomeIcon icon={faBed} />
              <span>: 2</span>
              <FontAwesomeIcon icon={faShower} />
              <span>: 1</span>
            </div>
            <div className="containerwhatsapp">
              <button onClick={theterracewa} className="whatsapp">
                <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default produk;
