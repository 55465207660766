import Br21 from "./2br (1).webp";
import Br22 from "./2br (2).webp";
import Br23 from "./2br (3).webp";
import Br24 from "./2br (4).webp";
import Br25 from "./2br (5).png";
import Br26 from "./2br (6).png";

const br2images = [Br21, Br22, Br23, Br24, Br25, Br26];

export default br2images;
