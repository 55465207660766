import Apart1 from "./apart (1).webp";
import Apart2 from "./apart (2).webp";
import Apart3 from "./apart (3).webp";
import Apart4 from "./apart (4).webp";
import Apart5 from "./apart (5).png";
import Apart6 from "./apart (6).png";
import Apart7 from "./apart (7).webp";

const apartimages = [Apart1, Apart2, Apart3, Apart4, Apart5, Apart6, Apart7];

export default apartimages;
