import React from "react";
import "./fasilitas.scss";
import surroundingimages from "../../asset/surounding/surounding";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const fasilitas = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for your mobile size
        settings: {
          slidesToShow: 1,
          arrows: false, // Show only 1 slide on mobile screens
        },
      },
    ],
  };
  return (
    <div id="fasilitas" className="fasilitas">
      <div className="container-fasilitas">
        <div className="header-fasilitas">
          <div className="judul-fasilitas">
            <b>NEW JAKARTA</b> IS READY TO <b>MAKE OVER</b>
          </div>
          <div className="desk-judul">
            INFRASTRUCTURE DEVELOPMENT IN THE TRANSPORTATION SECTOR IS ABLE TO
            CONNECT PRODUCTION AREAS WITH DISTRIBUTION AREAS AND FACILITATE
            ACCESS TO TOURIST AREAS.
          </div>
        </div>
        <div className="carousel-fasilitas">
          <Slider {...settings}>
            {surroundingimages.map((image, index) => (
              <img
                className="kartugambar2"
                key={index}
                src={image}
                alt={fasilitas}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default fasilitas;
