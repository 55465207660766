import Br11 from "./1br (1).webp";
import Br12 from "./1br (2).webp";
import Br13 from "./1br (3).webp";
import Br14 from "./1br (4).webp";
import Br15 from "./1br (5).webp";
import Br16 from "./1br (6).webp";
import Br17 from "./1br (7).webp";

const br1images = [Br11, Br12, Br13, Br14, Br15, Br16, Br17];

export default br1images;
